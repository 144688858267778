<template>
  <v-form @submit.prevent="list(true)">
    <v-container>
      <v-row>
        <v-col :cols="12">
          <span class="text-h5"> Dispositivos </span>
        </v-col>
      </v-row>
      <v-form @submit.prevent="list(true)">
        <v-row>
          <v-col :cols="12">
            <v-card>
              <v-card-title class="card-title">
                <v-icon class="mr-1">mdi-magnify</v-icon>
                Filtro</v-card-title
              >
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="3">
                    <v-text-field
                      label="Assinante"
                      v-model="deviceList.filter.subscriber_name"
                      v-debounce:300ms="list"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <v-text-field
                      label="Modelo"
                      v-model="deviceList.filter.model"
                      v-debounce:300ms="list"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <v-text-field
                      label="MAC Address"
                      v-model="deviceList.filter.mac_address"
                      v-debounce:300ms="list"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <v-text-field
                      label="Serial"
                      v-model="deviceList.filter.serial_number"
                      v-debounce:300ms="list"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
      <v-row>
        <v-col :cols="12">
          <v-card>
            <v-card-text>
              <Paginator
                v-bind.sync="deviceList.pagingOptions"
                @page="pageSelected"
              ></Paginator>

              <v-data-table
                :headers="deviceList.headers"
                :items="deviceList.data"
                :items-per-page="25"
                :disable-sort="true"
                :loading="deviceList.loading"
                no-data-text=""
                loading-text="Carregando dados..."
                class="app-table hover-table"
                @click:row="viewItem"
                hide-default-footer
              >
                <template v-slot:[`item.subscriber`]="{ item }">
                  <span class="list-item-title"
                    >{{ item.first_name }} {{ item.last_name }}</span
                  ><br />
                  <span class="list-item-subtitle">{{ item.cpf_cnpj }}</span>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <v-chip
                    small
                    :color="
                      item.status
                        | enum(data.streamingDeviceStatus, 'id', 'class')
                    "
                    dark
                    >{{
                      item.status | enum(data.streamingDeviceStatus)
                    }}</v-chip
                  >
                </template></v-data-table
              >
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { eventHub } from "@/main";
import streamingDeviceApi from "@/common/api/streaming-device.api";
import clientApi from "@/common/api/client.api";
import streamingDeviceStatus from "@/common/data/streaming-device-status.data";
import Paginator from "@/components/layout/Paginator";

export default {
  name: "StreamingDeviceList",

  components: { Paginator },

  data: () => ({
    data: {
      streamingDeviceStatus: streamingDeviceStatus,
    },

    deviceList: {
      filter: {},
      headers: [
        { text: "Assinante", value: "subscriber" },
        { text: "Modelo", value: "model" },
        { text: "Identificador", value: "identifier" },
        { text: "MAC Address", value: "mac_address" },
        { text: "Serial", value: "serial_number" },
        { text: "Status", value: "status" },
      ],
      data: [],
      loading: false,
      pagingOptions: {
        currentPage: 1,
      },
    },
  }),

  beforeDestroy() {
    eventHub.$off("SELECTED_CLIENT");
  },
  mounted() {
    eventHub.$on("SELECTED_CLIENT", (_) => this.list(true));
  },
  created() {
    this.list();
  },

  methods: {
    pageSelected(page) {
      this.deviceList.pagingOptions.currentPage = page;
      this.list();
    },
    
    list(resetPage) {
      if (resetPage) {
        this.deviceList.pagingOptions.currentPage = 1;
      }

      this.deviceList.filter.providerId = clientApi.getSelectedClient().id;
      this.deviceList.filter.page = this.deviceList.pagingOptions.currentPage;

      this.deviceList.loading = true;
      streamingDeviceApi.list(this.deviceList.filter).then((result) => {
        this.deviceList.loading = false;
        this.deviceList.data = result.data.data;
        this.deviceList.pagingOptions = result.data.pagingOptions;
      });
    },

    viewItem(item) {
      this.$router.push(`dispositivos/${item.id}`);
    },
  },
};
</script>